(function (WON, $, window, document) {
  'use strict';
  //## GENERAL Scripts
  var generalModule = (function () {
    var GLOBS = {
      ngoForm: document.getElementById('ngo_form'),
      menuModal: document.getElementById('menu_modal'),
      searchModal: document.getElementById('search_modal'),
      hamburger: document.getElementsByClassName('hamburger'),
      searchInput: document.getElementById('search_input'),
      searchToggler: document.getElementById('search_toggler'),
      singleLightBox: document.getElementById('lightbox_slider'),
      modalTrigger: document.getElementById('menu_modal_trigger'),
      fullModals: document.getElementsByClassName('header_modal'),
      tabsContent: document.querySelectorAll('.tab_content__item'),
      defaultSelects: document.querySelectorAll('.default_select'),
      bodyContent: document.getElementById('body_content_holder'),
      accordionToggle: document.querySelectorAll('.accordion_title'),
      closeHamburger: document.getElementsByClassName('close_hamburger'),
      tabsHeaderItem: document.querySelectorAll('.tabs_header__list li'),
      activeClass: 'active',
    };
    //Binds all handle functions to a new function which is called @init
    var bindEvents = function () {
      function toggleClassBySibling(elem) {
        var current = elem,
          siblings = current.parentNode.children;

        if (!current.classList.contains(GLOBS.activeClass)) {
          for (var i = 0; i < siblings.length; i++) {
            if (siblings[i] == current) {
              siblings[i].classList.add(GLOBS.activeClass);
            } else {
              siblings[i].classList.remove(GLOBS.activeClass);
            }
          }
        }
      }

      function toggleClassByNumb(elem, number) {
        for (var i = 0; i < elem.length; i++) {
          elem[i].classList.remove(GLOBS.activeClass);
        }
        elem[number].classList.add(GLOBS.activeClass);
      }

      if (GLOBS.closeHamburger != null) {
        var items = GLOBS.closeHamburger;

        function closeModals() {
          var modals = GLOBS.fullModals;
          for (var v = 0; v < modals.length; v++) {
            modals[v].classList.remove(GLOBS.activeClass);
          }
          GLOBS.bodyContent.classList.remove('menu_toggled');
        }

        for (var i = 0; i < items.length; i++) {
          items[i].addEventListener('click', closeModals);
        }
      }

      // if(GLOBS.tabsHeaderItem != null) {
      //   var items = GLOBS.tabsHeaderItem,
      //       activeTab = 0;

      //   function toggleTabs() {
      //     var currentContainer = this.parentNode.parentNode.parentNode,
      //         currentContent = currentContainer.querySelector('.tab_content__list').children;
      //     // Toggle Tab Header
      //     toggleClassBySibling(this);

      //     // Get Active Tab Index
      //     for(var i = 0; i < items.length; i++) {
      //       if(items[i].classList.contains(GLOBS.activeClass)) {
      //         activeTab = i;
      //       }
      //     }

      //     toggleClassByNumb(currentContent,activeTab);
      //   }

      //   for(var i = 0; i < items.length; i++) {
      //       items[i].addEventListener("click",toggleTabs);
      //   }
      // }

      if (GLOBS.defaultSelects != null) {
        var items = GLOBS.defaultSelects;

        for (var i = 0; i < items.length; i++) {
          items[i].addEventListener('change', handleValues);
        }

        function handleValues() {
          var newValue = this.value == 0 ? this[0].innerHTML : this.value,
            parent = this.parentNode;

          parent.children[0].innerHTML = newValue;
        }
      }

      if (GLOBS.modalTrigger != null) {
        function toggleHamburger() {
          console.log(GLOBS.bodyContent);
          GLOBS.bodyContent.classList.add('menu_toggled');
          GLOBS.menuModal.classList.add(GLOBS.activeClass);
        }
        GLOBS.modalTrigger.addEventListener('click', toggleHamburger);
      }
      if (GLOBS.searchToggler != null) {
        function toggleSearch() {
          GLOBS.bodyContent.classList.add('menu_toggled');
          GLOBS.searchModal.classList.add(GLOBS.activeClass);
          window.setTimeout(function () {
            GLOBS.searchInput.focus();
          }, 200);
        }

        GLOBS.searchToggler.addEventListener('click', toggleSearch);
      }
      if (GLOBS.accordionToggle != null) {
        function toggleAccordion() {
          var parent = this.parentElement,
            parentSiblings = parent.parentElement.children;

          for (var i = 0; i < parentSiblings.length; i++) {
            if (parentSiblings[i] == parent && !parent.classList.contains('active')) {
              parentSiblings[i].classList.add('active');
            } else {
              parentSiblings[i].classList.remove('active');
            }
          }
        }

        for (var i = 0; i < GLOBS.accordionToggle.length; i++) {
          GLOBS.accordionToggle[i].addEventListener('click', toggleAccordion);
        }
      }

      // LightBox Effect
      if (GLOBS.singleLightBox != null) {
        var images = document.querySelectorAll('.single_lightbox__item img');

        function closeLightBox() {
          var lightBox = document.getElementById('single_lightbox');
          if (lightBox != null) {
            lightBox.classList.add('faded');

            setTimeout(function (e) {
              lightBox.parentNode.removeChild(lightBox);
              GLOBS.bodyContent.classList.remove('active_lightbox');
            }, 300);

            function bindKeyEvents() {
              return null;
            }
            function addClickEvent() {
              return null;
            }
          }
        }

        function addClickEvent() {
          document.addEventListener('click', function (event) {
            if (GLOBS.bodyContent.classList.contains('active_lightbox')) {
              var eventTarget = event.originalTarget,
                isIMG = null;

              if (eventTarget == undefined) {
                isIMG = event.target.classList.contains('lightbox_content__img');
              } else {
                isIMG = event.originalTarget.classList.contains('lightbox_content__img');
              }

              if (!isIMG) {
                closeLightBox();
              }
            }
          });
        }

        function addLightBox(src) {
          var content = '';

          content += '<div class="lightbox_content" id="single_lightbox">';
          content += '<div class="lightbox_content__thumb">';
          content += '<img class="lightbox_content__img" src="' + src + '"/>';
          content += '</div>';
          content += '<span id="close_icon" class="close_icon"></span>';
          content += '</div>';

          setTimeout(function (e) {
            GLOBS.bodyContent.insertAdjacentHTML('beforeend', content);
            GLOBS.bodyContent.classList.add('active_lightbox');
          }, 100);
        }

        function bindKeyEvents() {
          document.onkeydown = function (event) {
            var isEsc = event.key == 'Esc' || event.key == 'Escape';
            if (isEsc) {
              closeLightBox();
            }
          };
        }

        function toggleLightBox() {
          var imgSrc = this.attributes.src.value;

          addLightBox(imgSrc);
          bindKeyEvents();
          addClickEvent();
        }

        for (var i = 0; i < images.length; i++) {
          images[i].addEventListener('click', toggleLightBox);
        }
      }

      function ieObjectFit() {
        $('.ie_object_fit').each(function () {
          var $container = $(this),
            img = $container.find('img'),
            imgUrl = img.prop('src');
          if (imgUrl) {
            img.css('opacity', '0');
            $container.css('backgroundImage', 'url(' + imgUrl + ')').addClass('custom-object-fit');
          }
        });
      }

      if (
        /Edge/.test(navigator.userAgent) ||
        navigator.appName == 'Microsoft Internet Explorer' ||
        !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) ||
        (typeof $.browser !== 'undefined' && $.browser.msie == 1)
      ) {
        $('.thumb_holder').addClass('ie_object_fit');
        ieObjectFit();

        $('html').addClass('ie');
        console.log('Developed by: Kutia Digital Agency http://kutia.net');
      } else {
        console.log('%c Developed by: Kutia Digital Agency http://kutia.net', 'padding:9px 5px; background:#3c4952; line-height:25px;color: #59bf9d');
      }
    };
    //End BIND
    //Inits Functions and Events
    var init = function () {
      bindEvents();
    };
    //END INIT
    //Inits Plugins
    var initPlugins = function () {
      // jQuery
      $(document).ready(function () {
        $('#slider_1').owlCarousel({
          loop: true,
          dots: true,
          center: true,
          responsive: {
            1024: {
              items: 4,
            },
            768: {
              items: 3,
            },
            320: {
              items: 2,
            },
          },
        });
        $('#main_slider').owlCarousel({
          items: 1,
          nav: true,
          loop: true,
          dots: false,
        });

        $('#lightbox_slider').owlCarousel({
          items: 1,
          loop: true,
          dots: false,
          mouseDrag: false,
        });
      });
    };

    //END INIT PLUGINS
    return {
      init: init,
      initPlugins: initPlugins,
    };
  })();
  //## END GENERAL Scripts
  //## WON MAIN
  var WONMain = (function () {
    var init = function () {
      generalModule.init();
      generalModule.initPlugins();
    };
    return {
      init: init,
    };
  })();
  //## END WON MAIN
  //Doc Ready
  document.addEventListener(
    'DOMContentLoaded',
    function () {
      WONMain.init();
    },
    false
  );
})((window.WON = window.WON || {}), window.jQuery, window, document);
